$bar-padding: 4px;

.bar {
	width: 100%;
	height: 16px;
	padding: $bar-padding;
	display: flex;
	border-radius: 9999px;
	position: relative;
	box-sizing: border-box;
	
	.bar-value {
		height: 100%;
		display: flex;
		margin: 0;
		padding: 0;
		// position: absolute;
		// top: $bar-padding;
		// bottom: $bar-padding;
		border-radius: 9999px;
		justify-content: space-between;
	}
}