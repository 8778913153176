/** PUT ALL YOUR HACKS TO MAKE DIFFERENT PARTS OF SITE WORKM TOGETHER HERE */

@media screen and (max-height: 650px) {
	.r-tab-row {
		position: relative !important; // Fix #62 where tabs overlapped - can't apply generally as messes with desktop spacing
	}

	// This further necessiates a bit of events padding
	.events {
		margin-top: 50px;
	}
}