// Useful mixins

// No top or bottom margin on a component
@mixin no-margin-top-bottom {
	margin-top: 0;
	margin-bottom: 0;
}


// Fully centred flex
@mixin flex-fully-centred {
	display: flex;
	align-items: center;
	justify-content: center;
}