@mixin light-card-styles {
	background: black;
	color: black;
	border: 2px solid white;
	margin: -2px;
	font-weight: 700;
}

@mixin light-panel-styles {
	background: white;
	color: var(--text-default);
}

.card {
	border-radius: 12px;
	padding: 4px;
	gap: 4px;
	display: flex;
	flex-direction: column;
	background: var(--panel-text, white);
	max-width: 480px;
	flex-grow: 1;
	margin-bottom: auto;

	.panel {
		border-radius: 8px;
		background: var(--panel-bg, black);
		padding: 16px;
		gap: 20px;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		&.light {
			@include light-panel-styles();
		}
	}

	&.light {
		@include light-card-styles();

		.panel {
			@include light-panel-styles();

			&.dark {
				color: white;
				// background: black;
				font-weight: 500;
			}
		}
	}

	.title {
		text-align: center;
	}

	.description {
		text-align: left;
		white-space: pre-line;
	}

	.stats {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.stat {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;

			.stat-label {
				margin-right: auto;
				text-align: left;
				padding-right: 1em;
			}

			.stat-value {
				margin-left: auto;
				text-align: right;
			}
		}
	}

	.cta {
		text-align: right;
		white-space: pre;
		background-color: var(--site-primary);
		color: var(--text-default);
		user-select: none;

		transition-duration: 100ms;

		// &:hover {
		// 	filter: brightness(80%);
		// }

		&:hover:active {
			filter: brightness(60%);
		}
	}
}