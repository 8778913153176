/** Styles for the jumbotron (the part that, currently, shows what week it is */
@import "variables";
@import "breakpoints";

.isitweeka-jumbotron a {
	color: var(--link-blue);
	display: inline-flex;

}

// Charity Week delays
a.cw-delayed button {
	background-color: #202435 !important;
	border: solid 2px lighten(#202435, 50%) !important;
	opacity: 0.5 !important;
}

a.cw-delayed, a.cw-delayed button {
	cursor: default !important;
}

$grid-gap: 20px;

.cw-widget {
	display: none;
	padding: 20px;
	background-color: #202435;
	border-radius: 12px;
	max-width: 75vw;
	margin-top: 15px;
	h1, h2, button p {
	// font-family: aniuk, sans-serif !important;
		font-weight: 700;
		font-style: normal;
	}
	h2 {
		font-size: 1.3em;

	}

	> h2 {
		margin-top: 0;
	}

	.raised-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 20px;
	}

	.ring-cont {
		width: 80px;
		margin-right: 25px;

	}

	.CircularProgressbar-trail {
		stroke: rgba(255, 255, 255, 0.0605) !important;
	}
	.CircularProgressbar-path {
		stroke: #ffc255 !important;
	}

	text {
	// font-family: aniuk, sans-serif;
		font-weight: 700;
		font-style: normal;
		fill: #fff !important;
		//transform: scaleX(0.9);
		text-align: center;
		font-size: 1.8em !important;
	}

	.raised-text {
		flex: 1;

		h3 {
			text-align: right;
			margin: 0;
			font-size: 1.5em;
		}


		h1 {
			margin: 0;
			font-size: 3em;
			text-align: right;
			line-height: 50px;
		}

		display: flex;
		flex-direction: column;
		justify-content: right;
	}

	button {
		background: #01b960;
		border: 1.18069px solid #00753C;
		box-sizing: border-box;
		border-radius: 7.08414px;
		width: 100%;
		text-align: center;

		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 1.4em;
		p {
			margin-right: 10px;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		svg {
			margin-top: 1px;
		}
	}
}


.isitweeka-jumbotron {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0;
	padding-left: var(--horizontal-indent);
	padding-right: var(--horizontal-indent);
	min-height: 100vh;

	@include phablet {
		flex-direction: column;
		// margin-left: var(--horizontal-indent);
		padding: 10vh var(--horizontal-indent);
	}

	.isitweeka {
		flex: 1;
		padding: 0;
		height: 100%;
		min-height: 100%;
	}

	h1, h2, h3, h4, h5, h6 {
		line-height: 1.5;
		margin: 0;
	}

	h1 {
		font-weight: 600;
	}

	.cw-cards {
		// Removed margin - Maddie
		// margin-top: 180px;
		// width: 60%;
		@include phablet {
			width: 100%;
			//margin: auto;
		}
		/*.cw-content {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 0px $grid-gap;
		}*/

		.cw-content {
			display: flex;
			justify-content: center;
			//margin-left: -1px;
			> div {
				// width: min-content; // Design tweak: Allow it to be wider
				// border: solid 4px;
				@include phablet {
					width: 100%;
					box-sizing: border-box;
				}
			}
		}

		// h1, h2, button p {
		// // font-family: aniuk, sans-serif !important;
		// 	// font-weight: 700;
		// 	// font-style: normal;
		// 	// font-size: 1.5em;
		// 	@include phablet {
		// 		font-size: 1.2em;
		// 	}
		// }

		h1 {
			text-align: right;
			width: 100%;
			margin-top: 0;
			margin-bottom: 10px;
			font-size: 3.5em;
			@include phablet {
				font-size: 3em;
			}
		}

		// h2 {
		// 	margin-bottom: 13px;
		// }

		h4 {
			margin-top: 0;
			font-size: 20px;
			@include phablet {
				font-size: 18px;
			}
		}

		h3, h4, h5, h6, p {
		// font-family: aniuk, sans-serif;
			font-weight: 400;
			font-style: normal;
		}

		.cw-header {
			padding-right: 20px;
			@include phablet {
				padding-right: 0;
				h1 {
					text-align: center;
				}

			}
		}

		// .cw-charity-link {
		// 	text-align: center;
		// 	a, p {
		// 		display: inline;
		// 		text-align: center;
		// 	// font-family: aniuk, sans-serif;
		// 		font-weight: 400;
		// 		font-style: normal;
		// 		font-size: 16px;
		// 	}
		// 	a {
		// 		margin-left: 5px;
		// 	}
		// }

		text {
		// font-family: aniuk, sans-serif;
			font-weight: 700;
			font-style: normal;
			fill: #fff !important;
			//transform: scaleX(0.9);
			text-align: center;
			font-size: 2em;
		}

		.cw-raised {
			overflow: hidden;

			.raised-content {
				// padding: 0 50px;
				display: flex;
				flex-direction: row;
				// justify-content: center;
				align-items: center;
				gap: 40px;
				padding-right: 20px;

				@include phablet {
					padding: 0;
					// justify-content: space-between;
					justify-content: center;
					gap: 20px;
				}

				h1 {
					flex: 1;
					text-align: center;
					font-size: 4em;
					//transform: scaleX(0.9);
					// line-height: 70px;
					line-height: 1.2;

					@include phablet {
						font-size: 2.5em;
					}
				}

				// @include phablet {
				// 	.raised-text:nth-child(2) {
				// 		display: none;
				// 	}
				// }

				.raised-text {
					display: flex;
					//flex: 1;
					// margin-left: 40px;
					flex-direction: column;
					justify-content: flex-end;
					align-items: flex-end;
					align-content: flex-end;
					text-align: right;



					h1, h3 {
						margin-bottom: 0;
						margin-top: 0;
						text-align: right;
					}

					h3 {
						padding-right: 4px;

						@include tablet {
							font-size: 1.5em;
						}
					}
				}
			}

			.ring-cont {
				min-width: 120px;
				width: 120px;
				margin: 5px 0;
				// padding-left: 20px;
				padding-bottom: 20px;

				@include phablet {
					padding-top: 20px;
				}
			}

			.CircularProgressbar-trail {
				stroke: rgba(255, 255, 255, 0.0605);
			}
			.CircularProgressbar-path {
				stroke: #ffc255;
			}

			.cw-raised h2 {
				margin-bottom: 20px;
			}


		}
		.cw-content > div {
			// padding: 20px;
			background-color: #202435;
			border-radius: 12px;
			margin-bottom: 20px;
			// & > div {
			// 	margin-bottom: $grid-gap;
			// }
		}

		.cw-content > div > div:last-child {
			margin-bottom: 0;
		}

		.cw-buy {
			gap: 20px;
			display: flex;
			flex-direction: column;
		}

		// .cw-buy a {
		// 	width: 100%;
		// }

		// .cw-buy button {
		// 	background: #01b960;
		// 	border: 1.18069px solid #00753C;
		// 	box-sizing: border-box;
		// 	border-radius: 7.08414px;
		// 	width: 100%;
		// 	text-align: center;

		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	color: #fff;
		// 	p {
		// 		margin-right: 10px;
		// 		margin-top: 10px;
		// 		margin-bottom: 10px;
		// 	}

		// 	&::after {
		// 		display: none;
		// 	}

		// 	&:hover {
		// 		background: #008143;
		// 		cursor: pointer;
		// 	}

		// 	@include mobile {
		// 		padding: 0 20px;
		// 		line-height: 35px;
		// 	}
		// }
	}
}

@include mobile {
	.isitweeka-jumbotron {
		/*padding-top: 80px;*/
		box-sizing: border-box;
	}
}

@include phablet {
	// Jumbotron socials fixes
	/* Socials fixes */
	.isitweeka-jumbotron .social-container {
		justify-content: center !important;
		transform: scale(0.8) translate(0, 60px) !important;
	}
}@include phablet {
	.cw-widget {
		display: flex !important;
		flex-direction: column;
	}

	.cw-cards {
		margin-top: 0 !important;
	}

	.cw-card {
		max-width: initial!important;
	}

	// CW stuff
	.isitweeka {
		h2.mobile {
			font-size: 2em;
		}

		h1.mobile {
			font-size: 5em;
		}

		#event-scroll-button {
			display: none;
		}

		.social-container {
			margin-top: -20px;
		}
	}
}

// Control the styling of the "if you believe this is in error, please contact <email>" shown
// when the system can't detect A or B
// Without this it is inline-flex and so on mobile looks incorecct as the email link and header text don't appear as one line - see issue #35
#neitherAB-contact {
	display: block;
	// margin: 20px 0;
	margin-bottom: 20px;
}
