@font-face {
    font-family: "Transport";
    src: local('Road UA Thin'), url('RoadUA-Thin.woff2') format('woff2'), url('RoadUA-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Transport";
    src: local('Road UA ExtraLight'), url('RoadUA-ExtraLight.woff2') format('woff2'), url('RoadUA-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Transport";
    src: local('Road UA Light'), url('RoadUA-Light.woff2') format('woff2'), url('RoadUA-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Transport";
    src: local('Road UA Regular'), url('RoadUA-Regular.woff2') format('woff2'), url('RoadUA-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Transport";
    src: local('Road UA Medium'), url('RoadUA-Medium.woff2') format('woff2'), url('RoadUA-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Transport";
    src: local('Road UA Bold'), url('RoadUA-Bold.woff2') format('woff2'), url('RoadUA-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Transport";
    src: local('Road UA ExtraBold'), url('RoadUA-ExtraBold.woff2') format('woff2'), url('RoadUA-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Transport";
    src: local('Road UA Black'), url('RoadUA-Black.woff2') format('woff2'), url('RoadUA-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}