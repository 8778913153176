@import "breakpoints";
@import "variables";

/** Styles for top tabs (the KECHB and KECHG labelled switch buttons) */
// Root container of tabs
.r-tab-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;

	// Row of tabs
	// Positioned slap bag at the top (hence position: absolute)
	.r-tab-row {
		display: flex;
		width: 100%;
		justify-content: center;
		/*height: 40px;*/
		padding: 68px 24px 0;
		box-sizing: border-box;
		position: absolute;
		gap: 40px;
	}

	// Base tabe style
	// .r-tab {
	// 	font-size: 2em;
	// 	padding-bottom: 8px;
	// 	padding-top: 3px;
	// 	height: 40px;
	// }
	
	.r-tab {
		font-size: 1.5em;
		line-height: 1.5;
		// height: 40px;
		font-weight: 400;
	}

	.r-tab.active {
		font-weight: 800;
	}

	// Underline for actibe tab
	.r-tab.active::after {
		content: "";
		position: absolute;
		top: 100%;
		left: -1px;
		right: -1px;
		height: 4px;
		background-color: var(--site-primary);
	}
}

// Some phablet adjustments
@include phablet {
	.r-tab-row {
		padding-top: 40px !important;
	}

	.r-tab {
		font-size: 1.75em !important;
	}
}

