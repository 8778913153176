/**
 * Contains the base styles of the site, mostly responsbile for setting up the body, app container, etc so they expand and fit the page.
 * Also sets up main site theme of black BG and white text
 */
@import "variables";
@import "breakpoints";

// Grab our fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"); // Open Sans
@import url("../fonts/Metropolis/stylesheet.css"); // OLD MAIN font Metropolis
@import url("../fonts/Transport/road-ua.css"); // MAIN font Transport

// Orgiinally from create-react-app, isn't really relevant here
body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// OUR STUFF:
/**
 * Setup:
 * - fonts (default is Metropolis
 * - site background colour (black)
 * - site text colour default (white)
 * - expand site to fite whole page
 */
html, body, #root, .App {
  margin: 0;
  min-height: 100%;
  background-color: $background-default;
	color: $text-default;
	font-family: "Transport", "Metropolis", "Open Sans", sans-serif;
	font-weight: 500;
}

h1, h2, h3, h4, h5, h6, p, span {
	font-family: "Transport", "Metropolis", "Open Sans", sans-serif;
}

/** Setup flex structure of application - .App is the react root of the site */
.App {
	justify-content: center;
	flex-direction: column;
}

/** .isitweeka - wrapper class for site sections */
.isitweeka {
	// Maximise
	min-height: 100vh;
	/* Prevent overlap with mail list. Readded on mobile to prevent thing looking weird (left commented out here) */
	/*height: 100vh;*/
	// Fill container element width (and not over)
	width: 100%;

	
	// Setup flex displaying
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	// Indent
	box-sizing: border-box;
	padding: 0 var(--horizontal-indent);
}

/* HACK */
@include phablet {
	#root {
		overflow-x: hidden;
	}
}

// Default showings of mobile and desktop only classes
.mobile {
	display: none !important;
	@include phablet {
		& {
			display: inline-block !important;
		}
	}
}

.hide-mobile {
	@include mobile {
		display: none!important;
	}
}

.hide-tablet {
	@include tablet {
		display: none!important;
	}
}

.hide-desktop {
	@include desktop {
		display: none!important;
	}
}

.desktop {
	display: inherit !important;
	@include phablet {
		& {
			display: none !important;
		}
	}
}

// "it stops links being default blue, and removes the underline" - Madeline
a {
	color: inherit;
	text-decoration: none;
}