.text {
	margin: 0;
}

.text.centred {
	text-align: center;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
}

.text.title {
	font-size: 48px;
	line-height: 72px;
	font-weight: 500;
}

.text.week {
	font-size: 112px;
	line-height: 168px;
	font-weight: 500;
}

// Potential new values: 24px/30px
.text.big {
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;
}

.text.control {
	font-size: 36px;
	line-height: 48px;
	font-weight: 500;
}

.text.control-active {
	font-weight: 800;
}

.text.control-inactive {
	font-weight: 400;
}

// Potential new values: 16px/24px
.text.body {
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
}

.light {
	h1, h2, h3, h4, h5, h6 {
		font-weight: 600;
	}
	
	.text.title {
		font-weight: 600;
	}
	
	.text.week {
		font-weight: 600;
	}
	
	.text.big {
		font-weight: 600;
	}
	
	.text.control {
		font-weight: 600;
	}
	
	.text.control-active {
		font-weight: 900;
	}
	
	.text.control-inactive {
		font-weight: 500;
	}
	
	.text.body {
		font-weight: 500;
	}
}

.text.secondary {
	opacity: 0.6;
}