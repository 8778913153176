/** All styles related to buttons */
@import "variables";
@import "icons.scss";

button {
	color: inherit;

	display: inline-flex;
	flex-direction: row;

	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;

	font-size: 1.5em;
	line-height: 1.5;
	font-weight: 500;
	/*line-height: 26px;*/
	// text-transform: lowercase;
	text-decoration: none;

	transition-duration: 100ms;

	position: relative;
	cursor: pointer;
	
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	background: transparent;
	border: none;

	// padding-top: 3px;
	
	padding: 0;

	// Span I think is the button's actual contents
	& > span { // = button > span
		z-index: 2;
		// padding-top: 4px;
		white-space: pre;
		
	}

	// Button text is too low on Firefox with 5px padding.
	@-moz-document url-prefix() {
		& > span {
			padding-top: 1px !important;
		}		
	}
}

// Button forward icon
button.forward {
	/*margin-right: auto;*/
	/*margin-left: -8px;*/
	width: auto;

	// Add icon
  // @include arrow-right(48px, 8px);
	// &::after {
	// 	// margin: -8px;
	// }
	&[data-light=true]::after {
		filter: none;
		fill: #000 !important;
		z-index: 10;
	}

	&:hover::before {
		transform: scaleY(1) scaleX(1) translateY(-3px);
		left: -8px;
		right: -8px;
		border-radius: 4px;
	}
	
	&:hover:active::before {
		filter: brightness(80%);
	}
}

button.back {
	height: 100%;
	justify-content: center;
	flex-direction: column;
	font: inherit;

	// Add icon
	// @include arrow-left(48px, 16px);
}

// Add underlines
button.forward::before, button.back::after {
	content: "";
	background-color: var(--site-primary);
	/* background-image: linear-gradient(to right, var(--rykan-accent-primary), var(--rykan-accent-secondary)); */
	position: absolute;
	bottom: -4px;
	transition-duration: 100ms;
	pointer-events: none;
}
button.forward::before {
	// height: 100%;
	height: 40px;
	// width: 100%;
	// left: -4px;
	left: -0.5px;
	right: -0.5px;
	transform: scaleY(0.1) scaleX(1);
	transform-origin: 0 100%;
	z-index: 0;
}
// button.back::after {
// 	height: 3px;
// 	width: 100%;
// 	left: 0;
// 	transform: scaleX(0);
// 	transform-origin: 0 0;
// }

// A B tests for buttons
button.forward.a::before {
	transform: scaleY(0.1);
}

button.forward.b::before {
	transform: scaleY(1);
}

button.forward.b:hover::before {
	filter: saturate(75%);
}

/** Mobile margin reduction */
@include mobile {
	.forward {
		margin: 0 auto;
	}
}


// Custom button class. Instead of a transparent background the is filled on hover, this is filled by default
button.filled-button {
	width: auto;
	height: 2em;
	text-align: center;
	padding-top: 0px;
	padding-right: 1em;
	padding-left: 1em;
	background-color: $site-primary;
	color: $text-default;

	span {
		margin: auto;
		text-align: center;
		padding-top: 3px;
	}

	// Darken a filled button on hover
	&:hover {
		filter: saturate(60%);
	}
}
