/**
 * Contains styles for the alert banner
 */
/** Banner stuff */
@import "icons.scss";
@import "breakpoints";

// Root of the banner
.r-banner-container {
	top: 168px;
	left: 0;
	animation: slide-in-top 300ms both;
	animation-delay: 100ms;
	transform-origin: bottom center;
	position: absolute;
	width: 100%;
	min-height: 68px;
	display: flex;
	padding: 0 20px;
	box-sizing: border-box;

  // Use to animate banner out when dismissed
  // not yet used
  .r-banner-container-animate-out {
    animation-direction: reverse;
  }

  /** The way we do the banner onn phabelt and below is different */
  @include mobile {
    & {
      top: calc(min(20vh, 112px)) !important;
    }
  }

  @include phablet {
    & {
      top: calc(min(20vh, 144px));
      /*bottom: 28px;*/
      /*position: initial;*/
      animation: slide-in-bottom 300ms both;
      animation-delay: 100ms;
      padding: 0 10px;
      /*margin-top: 10px;*/
    }
  }
  
}

/** This is the actual banner itself and is modifed depending on alerts */
.r-banner {
	margin: 0 auto;
	padding: 20px;
	display: flex;
	box-sizing: border-box;
	border-radius: 12px;

  /* Text styling */
  // Mayt be modified by some alert levels
  h3 {
    display: flex;
    margin: auto 0;
    line-height: 1.2;
    text-transform: uppercase; // default is uppercase, explicitly set to 'none' by alert levels
    font-weight: 750;
    padding-top: 3px;
  }

  // Slighty different overall text sizes on desktop and mobile
  .desktop {
    font-size: 1.6em;
  }

  .mobile {
    font-size: 1.15em;
  }
}

// Dismiss button
.r-banner-dismiss {
	margin: auto auto auto 20px;
	color:rgba(255, 255, 255, 0.65);

  &:hover {
    cursor: pointer;
    color:rgba(255, 255, 255, 0.85);
  }
}

/** Only show as clickable if a link */
.r-banner-link {
	display: inline-flex !important;
	border-bottom: solid 3px;
	// text-transform: lowercase;
	color: #FFFFFF !important;
	height: 1em;
	line-height: 1;
	white-space: nowrap;
	margin: auto auto auto 20px;

  // Actual link text itself
  span {
    font-size: 1em;
    height: 1em;
    line-height: 1;
  }

  &:hover {
    cursor: pointer;
  }

  // Add icon
  // @include  arrow-right(1em, 4px);
	// &::after {
	// 	//margin: -8px;
  //   display: inline-block;
  //   transform: scale(2);
	// }
}

// Customer mobile styling for links
.mobile .r-banner-link {
	margin: 10px auto auto 0px;
}

// HACK: .not-x pushes the main jumtotron and rest of the page DOWN so the banner doesn't overlap with it
// Only necessary under a certain aspect ratio
@media screen and (max-aspect-ratio: 1/2) {
	.not-x {
		display: none!important;
	}
}

/** Alert levels */
.r-banner-alert-LOW {
	background-color: var(--great-northern-green);
	text-transform: none;
  h3 {
    text-transform: none;
  }
}

.r-banner-alert-INFO {
	background-color: var(--french-blue-brighter);
  h3 {
    text-transform: none;
  }
}

.r-banner-alert-MODERATE {
	background-color: var(--south-eastern-yellow);
	color: var(--north-western-blue);
	font-weight: 720;
}

.r-banner-alert-MEDIUM {
	background-color: var(--south-eastern-yellow);
	color: #000000;
}

.r-banner-alert-SUBSTANTIAL {
	background-color: var(--south-eastern-yellow);
	color: #000000;
}

.r-banner-alert-SEVERE {
	background-color: var(--an-orange);
}

.r-banner-alert-CRITICAL {
	background-color: var(--midland-red);
	/* background-color: #B80000 */
}

.r-banner-alert-DEATH {
  background-color: #212121;
  h3 {
    text-transform: none;
  }
}

