/** Styles for the YT container used to display the 2021 April Fools video. Useful if you want to put a video in the Jumbotron */

.yt-container {
	display: inherit;
	justify-content: center;
	margin-top: 120px;
}

@media screen and (min-width: 700px) {
	.yt-container {
		margin-top: 150px;
	}
}

@media screen and (max-width: 500px) {
	.yt-container iframe {
		width: 560px;
		height: 315px;
	}
}